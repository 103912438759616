export const SchemaHomepage = isEnglish => {
  const schemaEn = `{
        "@context": "https://schema.org",
        "@type": "HowTo",
        "name": "How To use Transfez Application",
        "description": "Application for Service transfer / sends money to various countries in the world that are safe, fast, and reliable.",
        "supply": [
          {
            "@type": "HowToSupply",
            "name": "Transfez App"
          }
        ],
        "tool": [
          {
            "@type": "HowToTool",
            "name": "Transfez App"
          }
        ],
        "step": [
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/en",
            "name": "Sign up and verify account",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Download the apps, sign up for free, verify your account."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/en",
            "name": "Enter the amount and choose the destination country",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Transfez will show information about rate and transaction fee."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/en",
            "name": "Fill in the recipient's information",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Enter the recipient’s information or choose from the existed recipient."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/en",
            "name": "Pay the amount as shown in the apps",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Transfer the amount as shown in the apps and your money is ready to be processed."
            }]
          }
        ]
      }`
  const schemaId = `{
        "@context": "https://schema.org",
        "@type": "HowTo",
        "name": "Bagaimana Cara Menggunakan Aplikasi Transfez",
        "description": "Aplikasi Layanan transfer / kirim uang ke berbagai negara di belahan dunia yang terpercaya, aman, cepat dan tepercaya.",
        "supply": [
          {
            "@type": "HowToSupply",
            "name": "Transfez App"
          }
        ],
        "tool": [
          {
            "@type": "HowToTool",
            "name": "Transfez App"
          }
        ],
        "step": [
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/",
            "name": "Daftar dan Verifikasi",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Unduh aplikasi, daftar gratis, lakukan verifikasi."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/",
            "name": "Tentukan jumlah uang dan negara tujuan",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Transfez akan menginformasikan biaya transfer dan nilai tukar mata uang."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/",
            "name": "Masukkan informasi penerima uang",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Masukkan data penerima uang atau pilih dari penerima yang sudah terdaftar."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/",
            "name": "Transfer sejumlah yang ditentukan",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Transfer jumlah yang ditentukan dan uangmu siap diproses."
            }]
          }
        ]
      }`
  const schema = isEnglish ? schemaEn : schemaId
  return schema
}
