export const MetaDataProps = props => {
  const isSg = props === "sg"
  const isSendMoney = props === "send-money"
  const isHomepage = props === "homepage"
  const isPrivacyPolicy = props === "privacy-policy"
  const isServiceAgreement = props === "service-agreement"
  const isSwiftCode = props === "swift-code"
  const isSwiftCodeDetails = props === "swift-code-details"
  const isBusiness = props === "business"
  const isBusinessSuccess = props === "business-success"
  const isAboutUs = props === "about-us"
  const isAffiliate = props === "affiliate"
  const isAffiliateForm = props === "affiliate-form"
  const isAffiliateSubmit = props === "affiliate-submitted"
  const isBICChecker = props === "bic-checker"
  const isContactUs = props === "contact-us"
  const isCookiePolicy = props === "cookie-policy"
  const isDownload = props === "download"
  const is404 = props === "error"
  const isFaq = props === "faq"
  const isForgotPass = props === "forgot-password"
  const isHowTo = props === "how-to"
  const isResetPass = props === "reset-password"
  const isSgService = props === "sg-service-agreement"
  const isSgPrivacy = props === "sg-privacy-policy"
  const isReferral = props === "referral"
  const isReferralTnc = props === "referral-tnc"
  const isSgReferral = props === "sg-referral"
  const isSgReferralTnc = props === "sg-referral-tnc"
  const isAdvanceFaq = props === "advance-faq"
  const isUpdateConfirmation = props === "update-confirmation"
  const isCountries = props === "countries"
  return {
    isSg,
    isSendMoney,
    isHomepage,
    isPrivacyPolicy,
    isServiceAgreement,
    isSwiftCode,
    isSwiftCodeDetails,
    isBusiness,
    isBusinessSuccess,
    isAboutUs,
    isAffiliate,
    isAffiliateForm,
    isAffiliateSubmit,
    isBICChecker,
    isContactUs,
    isCookiePolicy,
    isDownload,
    is404,
    isFaq,
    isForgotPass,
    isHowTo,
    isResetPass,
    isSgService,
    isSgPrivacy,
    isReferral,
    isReferralTnc,
    isSgReferral,
    isSgReferralTnc,
    isAdvanceFaq,
    isUpdateConfirmation,
    isCountries,
  }
}

export const MetaData = ({ title, country, swiftcodes }) => {
  const {
    isSg,
    isSendMoney,
    isHomepage,
    isPrivacyPolicy,
    isServiceAgreement,
    isSwiftCode,
    isSwiftCodeDetails,
    isBusiness,
    isBusinessSuccess,
    isAboutUs,
    isAffiliate,
    isAffiliateForm,
    isAffiliateSubmit,
    isBICChecker,
    isContactUs,
    isCookiePolicy,
    isDownload,
    is404,
    isFaq,
    isForgotPass,
    isHowTo,
    isResetPass,
    isSgService,
    isSgPrivacy,
    isReferral,
    isReferralTnc,
    isSgReferral,
    isSgReferralTnc,
    isAdvanceFaq,
    isUpdateConfirmation,
    isCountries,
  } = MetaDataProps(title)
  if (isHomepage) {
    const titleEn =
      "Send Money Abroad From Indonesia to 70+ Countries | Transfez"
    const titleId =
      "Aplikasi Transfer / Kirim Uang Ke Luar Negeri Cepat dan Aman"
    const descEn =
      "Transfer / send money abroad from Indonesia to 70+ countries that are safe, fast, and reliable. Send and track your transactions with Transfez App now!"
    const descId =
      "Aplikasi Layanan transfer / kirim uang ke luar negeri yang aman, cepat dan tepercaya. Lakukan pembayaran internasional dengan Transfez App sekarang!"
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isSg) {
    const titleEn =
      "Send Money from Singapore to Indonesia, Pay Bills & E-Wallet"
    const titleId =
      "Kirim Uang dari Singapura ke Indonesia & Bayar Tagihan PPOB"
    const descEn =
      "Fast, safe, transparent, and cheapest transfer or remittance service from Singapore to Indonesia, also pay bills and top up e-wallet at Transfez SG."
    const descId =
      "Transfez Singapura menyediakan layanan transfer / kirim uang dari Singapura ke Indonesia yang cepat, aman, dan transparan, bisa juga bayar PPOB."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }

  if (isSendMoney) {
    const titleEn = `Send Money to ${country.country_en} Easily, Quick, Safe and Cheapest`
    const titleId = `Kirim Uang ke ${country.country_id} dengan Mudah, Cepat, Aman dan Murah`
    const descEn = `Enjoy transfer money / send money to ${country.country_en} using the Transfez App easily, quickly, efficiently, securely, trusted, and transparently.`
    const descId = `Nikmati layanan transfer uang / kirim uang ke ${country.country_id} di Transfez App dengan mudah, cepat, hemat, aman, tepercaya dan transparan.`
    const keywordsEn =
      "money remittance services in indonesia,remittance money transfer company in indonesia,indonesia online remittance,international money transfer services,online money transfers between countries,trusted remittances in indonesia,send money,international"
    const keywordsId =
      "layanan pengiriman uang di indonesia,pengiriman uang perusahaan pengiriman uang di indonesia,pengiriman uang online indonesia,layanan transfer uang internasional,transfer uang online antar negara,pengiriman uang terpercaya di indonesia,kirim uang,transfer uang,luar negeri"
    const result = { titleEn, titleId, descEn, descId, keywordsEn, keywordsId }
    return result
  }

  if (isCountries) {
    const titleEn = `List of Countries For Send Money Abroad with Transfez App`
    const titleId = `Daftar Negara Pengiriman Uang ke Luar Negeri di Transfez`
    const descEn = `Send money to more than 70 countries. You can send money from Indonesia to many countries with Transfez App. Download on Google Play Store and Apple App Store.`
    const descId = `Kirim Uang ke Lebih dari 70 Negara. Langkah mudah mengirimkan uang dari Indonesia ke berbagai negara dengan Aplikasi Transfez, download sekarang.`
    const result = { titleEn, titleId, descEn, descId }
    return result
  }

  if (isSwiftCode) {
    const titleEn =
      "SWIFT Code / BIC Bank Online Checker - Complete List Banks | Transfez"
    const titleId =
      "Cek Kode SWIFT / BIC Online - Daftar Bank Lengkap | Transfez"
    const descEn =
      "Check the SWIFT / BIC code online for money transfer purposes between banks of different countries or internationally. Download Transfez app now!"
    const descId =
      "Cek kode SWIFT / BIC secara online untuk keperluan transfer uang antar bank beda negara atau secara internasional. Download aplikasi Transfez sekarang!"
    const keywordsEn =
      "money remittance services in indonesia,remittance money transfer company in indonesia,indonesia online remittance,international money transfer services,online money transfers between countries,trusted remittances in indonesia,check,swift code,bic. online,international,money transfer"
    const keywordsId =
      "layanan pengiriman uang di indonesia,pengiriman uang perusahaan pengiriman uang di indonesia,pengiriman uang online indonesia,layanan transfer uang internasional,transfer uang online antar negara,pengiriman uang terpercaya di indonesia,cek,kode swift,bic,online,transfer uang,internasional"
    const result = { titleEn, titleId, descEn, descId, keywordsEn, keywordsId }
    return result
  }

  if (isSwiftCodeDetails) {
    const titleEn = `SWIFT Code / BIC Bank ${swiftcodes.bank} | Transfez`
    const titleId = `Kode SWIFT / BIC Bank ${swiftcodes.bank} | Transfez`
    const descEn = `SWIFT / BIC code ${swiftcodes.bank} for money transfer purposes between banks of different countries. Download the Transfez app now!`
    const descId = `Kode SWIFT / BIC ${swiftcodes.bank} untuk keperluan transfer uang antar bank beda negara / internasional. Download aplikasi Transfez sekarang!`
    const keywordsEn =
      "money remittance services in indonesia,remittance money transfer company in indonesia,indonesia online remittance,international money transfer services,online money transfers between countries,trusted remittances in indonesia,check,swift code,bic,valbadad,international,money,transfer"
    const keywordsId =
      "layanan pengiriman uang di indonesia,pengiriman uang perusahaan pengiriman uang di indonesia,pengiriman uang online indonesia,layanan transfer uang internasional,transfer uang online antar negara,pengiriman uang terpercaya di indonesia,cek,kode swift,bic,valbadad,transfer,uang,internasional"
    const result = { titleEn, titleId, descEn, descId, keywordsEn, keywordsId }
    return result
  }

  if (isBusiness) {
    const titleEn = "Business Account Transfez | Payment Indonesia To Overseas"
    const titleId =
      "Pembayaran B2B Transaksi Bisnis Global | Akun Bisnis Transfez"
    const descEn =
      "Global business transactions are easier by registering your Transfez business account and make the transaction experience easier, cheaper, and faster."
    const descId =
      "Transaksi bisnis global lebih mudah dengan mendaftarkan akun bisnis Transfez Anda dan rasakan pengalaman bertransaksi lebih mudah, hemat, dan cepat."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isBusinessSuccess) {
    const titleEn =
      "Thank You for Filling Out Transfez Business Form | Transfez"
    const titleId = "Terimakasih Sudah Mengisi Form Transfez Bisnis | Transfez"
    const descEn =
      "Thank you for filling out Transfez Business form. Your submission will be checked by our team and will immediately send a response to your email."
    const descId =
      "Terimakasih telah mengisi form Transfez Bisnis. Kiriman Anda akan diperiksa oleh tim kami dan akan segera mengirimkan tanggapan ke email Anda."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }

  if (isPrivacyPolicy) {
    const titleEn = "Privacy Policy | Transfez"
    const titleId =
      "Kebijakan Privasi Transfez | Aplikasi Transfer Uang Antar Negara"
    const descEn =
      "Transfez's privacy policy is related to data protection, data information, browser cookies, and user privacy. Read more here."
    const descId =
      "Kebijakan privasi dari Transfez terkait dengan perlindungan data, informasi data, cookie browser dan tentang privasi pengguna selengkapnya baca disini."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isServiceAgreement) {
    const titleEn = "Transfez Service Agreement | Transfez"
    const titleId = "Perjanjian Layanan Transfez | Transfez"
    const descEn =
      "You can read the terms and conditions for using the Transfez Application here. Download Transfez on Google Play Store and App Store now!"
    const result = { titleEn, titleId, descEn }
    return result
  }
  if (isAboutUs) {
    const titleEn = "About Us - Trusted Send Money Abroad Platform"
    const titleId = "Tentang Kami - Aplikasi Kirim Uang Antar Negara"
    const descEn =
      "By using the Transfez Application, you can transfer/send money to various countries around the world easily, safely, quickly, and efficiently."
    const descId =
      "Dengan menggunakan Aplikasi Transfez, kamu bisa transfer/kirim uang ke berbagai negara di belahan dunia dengan mudah, aman, cepat dan hemat."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isAffiliate) {
    const titleEn =
      "Affiliate | Transfez | Trusted Indonesia Remittance Company"
    const titleId =
      "Mitra Afiliasi | Transfez | Aplikasi Transfer Uang Antar Negara"
    const descEn =
      "By joining as an affiliate partner in Transfez, get additional income for every new user who transacts on the Transfez application. Download now!"
    const descId =
      "Segera bergabung menjadi mitra afiliasi Transfez dan dapatkan penghasilan tambahan untuk setiap pengguna baru yang bertransaksi di aplikasi Transfez."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isAffiliateForm) {
    const titleEn = "Affiliate Form | Transfez"
    const titleId = "Formulir Afiliasi | Transfez"
    const result = { titleEn, titleId }
    return result
  }
  if (isAffiliateSubmit) {
    const titleEn =
      "Thank You for Filling Out The Affiliate Partner Form | Transfez"
    const titleId = "Terimakasih Sudah Mengisi Form Mitra Afiliasi | Transfez"
    const descEn =
      "Thank you for filling out the affiliate partner form. Your submission will be checked by our team and will immediately send a response to your email."
    const descId =
      "Terimakasih telah mengisi form mitra afiliasi. Kiriman Anda akan diperiksa oleh tim kami dan akan segera mengirimkan tanggapan ke email Anda."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isBICChecker) {
    const titleEn =
      "SWIFT Code / BIC Bank Online Checker - Complete List Banks | Transfez"
    const titleId =
      "Cek Kode SWIFT / BIC Online - Daftar Bank Lengkap | Transfez"
    const result = { titleEn, titleId }
    return result
  }
  if (isContactUs) {
    const titleEn = "Contact Us - Trusted Send Money Abroad Platform"
    const titleId = "Hubungi Kami - Aplikasi Kirim Uang Antar Negara"
    const descEn =
      "Questions regarding account, how to transact, cooperation, business accounts, and others can be via WhatsApp at 082298653838 & hello@transfez.com"
    const descId =
      "Pertanyaan mengenai pembuatan akun, cara bertransaksi, kerjasama, akun bisnis, dan lainnya bisa melalui WhatsApp di 082298653838 & hello@transfez.com"
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isCookiePolicy) {
    const titleEn = "Cookie Policy | Transfez"
    const titleId = "Kebijakan Cookie | Transfez"
    const result = { titleEn, titleId }
    return result
  }
  if (isDownload) {
    const titleEn = "Download - Trusted Send Money Abroad Platform"
    const titleId = "Download - Aplikasi Kirim Uang Antar Negara"
    const descEn =
      "Download Transfez Application on the Google Play Store and App Store for a fast, safe, and reliable experience of sending money to various countries."
    const descId =
      "Download Aplikasi Transfez di Google Play Store dan App Store untuk pengalaman mengirim uang ke berbagai negara dengan cepat, aman dan tepercaya."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (is404) {
    const titleEn = "404 Page | Transfez"
    const titleId = "404 Page | Transfez"
    const result = { titleEn, titleId }
    return result
  }
  if (isFaq) {
    const titleEn = "FAQ - Trusted Send Money Abroad Platform"
    const titleId = "FAQ - Aplikasi Kirim Uang Antar Negara"
    const descEn =
      "Do you have questions about Transfez? Get complete information from creating an account, how to transact, and other information in the Transfez FAQ."
    const descId =
      "Anda punya pertanyaan tentang Transfez? Dapatkan informasi lengkap mulai dari pembuatan akun, cara bertransaksi, dan informasi lainnya di FAQ Transfez."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isAdvanceFaq) {
    const titleEn =
      "Advance FAQ | Transfez | Trusted Indonesia Remittance Company"
    const titleId =
      "Advance FAQ | Transfez | Aplikasi Transfer Uang Antar Negara"
    const descEn =
      "Do you have questions about Transfez? Get complete information from creating an account, how to transact, and other information in the Transfez FAQ."
    const descId =
      "Anda punya pertanyaan tentang Transfez? Dapatkan informasi lengkap mulai dari pembuatan akun, cara bertransaksi, dan informasi lainnya di FAQ Transfez."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isForgotPass) {
    const titleEn = "Forgot Password | Transfez"
    const titleId = "Forgot Password | Transfez"
    const result = { titleEn, titleId }
    return result
  }
  if (isUpdateConfirmation) {
    const titleEn = "Update Confirmation | Transfez"
    const titleId = "Update Confirmation | Transfez"
    const result = { titleEn, titleId }
    return result
  }
  if (isHowTo) {
    const titleEn =
      "How to Top Up, Transfer, Pay Bills and Verification | Transfez"
    const titleId =
      "Bagaimana cara Top Up, Transfer, Bayar Tagihan dan Verifikasi| Transfez"
    const result = { titleEn, titleId }
    return result
  }
  if (isResetPass) {
    const titleEn = "Reset Password | Transfez"
    const titleId = "Reset Password | Transfez"
    const result = { titleEn, titleId }
    return result
  }
  if (isSgService) {
    const titleEn = "Transfez Singapore Service Agreement | Transfez"
    const titleId = "Perjanjian Layanan Transfez Singapura | Transfez"
    const result = { titleEn, titleId }
    return result
  }
  if (isSgPrivacy) {
    const titleEn = "Privacy Policy Singapore | Transfez"
    const titleId = "Kebijakan Privasi Singapura | Transfez"
    const result = { titleEn, titleId }
    return result
  }
  if (isReferral) {
    const titleEn = "Refer a Friend, Get Rp50.000 | Transfez Referral Program"
    const titleId =
      "Segera Bergabung Dengan Program Referral Transfez Indonesia"
    const descEn =
      "Share your referral code and get 50.000 points of reward worth Rp50.000 when they made transactions."
    const descId =
      "Dengan program referral Transfez, dapatkan 50.000 poin senilai Rp50.000 untuk setiap teman yang menggunakan kode kamu saat transaksi pertama."
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isReferralTnc) {
    const titleEn = "Referral Terms and Conditions | Transfez"
    const titleId = "Syarat dan Ketentuan Referral Program Terbaru | Transfez"
    const descId =
      "Syarat dan Ketentuan penggunaan Aplikasi Transfez bisa Anda baca disini. Download Trasfez di Google Play Store dan App Store sekarang!"
    const result = { titleEn, titleId, descId }
    return result
  }
  if (isSgReferral) {
    const titleEn =
      "Refer a Friend Get 7 SGD | Transfez Singapore Referral Program"
    const titleId = "Ayo Bergabung Dengan Program Referral Transfez Singapura"
    const descEn =
      "Share your referral code and get 7 SGD rewards when they made a minimum of 350 SGD transactions in Transfez Singapore Referral Program."
    const descId =
      "Bagikan kode referral kamu & dapatkan 7 SGD ketika temanmu berhasil melakukan transaksi senilai minimum 350 SGD di program referral Transfez Singapura"
    const result = { titleEn, titleId, descEn, descId }
    return result
  }
  if (isSgReferralTnc) {
    const titleEn =
      "Referral Terms and Conditions (Updated) | Transfez Singapore"
    const titleId = "Syarat dan Ketentuan Referral Program | Transfez Singapura"
    const descEn =
      "You can read the terms and conditions for using the Transfez Singapore application here. Download Transfez on Google Play Store and App Store now!"
    const descId =
      "Syarat dan Ketentuan penggunaan Aplikasi Transfez Singapura bisa Anda baca disini. Download Transfez di Google Play Store dan App Store sekarang!"
    const result = { titleEn, titleId, descEn, descId }
    return result
  }

  return {}
}
