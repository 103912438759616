export const SchemaFaq = isEnglish => {
  const schemaEn = `{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Is Transfez a licensed service provider?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Transfez is a brand of PT. Indo Koala Remittance, a remittance company licensed and regulated by Bank Indonesia"
              }
            }, {
              "@type": "Question",
              "name": "Is it safe to send money through Transfez?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Your safety is our priority. We maintain complete transparency to give you better control over your transaction and our thorough verification processes ensure that your money reaches the right person. We are regulated by Bank Indonesia regulation: 19/10/PBI/2017 with license no. 13/77/DASP/30 and PPATK regulation: PER-12/1.02/PPATK/06/13."
              }
            }, {
                "@type": "Question",
                "name": "How do I create an account with Transfez?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "It’s very easy. You just need to sign up with your email or Google/Facebook account through our app."
                }
              }, {
                "@type": "Question",
                "name": "Why is it important to complete my profile?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You need to complete your profile to send money with us and as a financial institution, we need to verify the identity of all our customers according to local regulations of the countries where we operate."
                }
              }, {
                "@type": "Question",
                "name": "Why do I have to give out my personal credentials in order to make a transaction?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "As a regulated financial entity, Transfez has a commitment to be subject to the Anti Money Laundering and Prevention of Terrorism Funding regulation of Bank Indonesia and PPATK. Your data will be screened to make sure that we are not in any way breaking the laws."
                }
              }, {
                "@type": "Question",
                "name": "How important is the accuracy of details I provide to Transfez?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "As a financial institution, we need to know who is transferring money with us and verify their identity. For this reason, when you create your profile, please use your real name (no nick name or initials), accurate address, phone number and an email where you can be reached."
                }
              }, {
                "@type": "Question",
                "name": "How long would it take for the profile verification process until I can do my first transaction?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The verification is only need to be done one time when you complete your profile for the first time. Once you completed your profile, it will take up to 1 x 24 hours of working days before you can use our service for the first time. But it is worth to mention that in average it took us less than 1 hour to verify your profile."
                }
              }, {
                "@type": "Question",
                "name": "Can I change my email or mobile number?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Please contact our Customer Service to change your email and mobile number."
                }
              }, {
                "@type": "Question",
                "name": "How do I add a recipient to my account?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "If you are a verified user, you can log in and click on “recipient” tab, or “create recipient” while setting up a transaction. If you are signing up, you can “create recipient” while setting up your first transaction"
                }
              }, {
                "@type": "Question",
                "name": "I have been asked for additional documents even after I complete my profile, why?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "As a policy, we require you to share your latest copies of your documents. In case, the documents you have shared are outdated and have expired, we will need you to share them again before we can process a transaction for you."
                }
              }, {
                "@type": "Question",
                "name": "Is it secure?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Not only do we have a remittance license from Bank Indonesia, we also have years of experience in the remittance business, successfully help clients moving millions of dollars."
                }
              }, {
                "@type": "Question",
                "name": "When I am instructed to fill in my credentials, will the information remain private?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Your privacy and trust are important for us. We collect your data in order to process your order and manage your account, in addition to fulfill our obligation to send reports to related authorities. We may send you emails and notifications on certain news and/or offers we might have--upon your consent. Transfez has an encryption system that has layers of protection that ensures your data’s safety. Information exchange over servers are also encrypted using SSL on servers located in Indonesia. Please provide your information only to our officers or representatives to prevent security issues regarding your data. More information, kindly read our Privacy Policy and Transfez Service Agreement."
                }
              },
              }, {
                "@type": "Question",
                "name": "How much fees do Transfez charge?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our fees differ from countries and corridors of transfers. The fees are subject to change and can be reviewed at any given time on the Transfez currency converter."
                }
              },
              }, {
                "@type": "Question",
                "name": "Why are the transaction fees at Transfez so different than banks and other operators?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "At Transfez, our aim is to make the international transfer feels like domestic in terms of fees, speed and convenience. Unlike banks and other operators, we managed to have liquidity in each of the countries through our partner and banks and will execute your transfer through liquidity of currency in every destination country that we serve, making our fees much cheaper than banks."
                }
              },
              }, {
                "@type": "Question",
                "name": "Do we really save money by using Transfez?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Absolutely. At Transfez, we do not have any hidden fees. What you see is what you receive. All fees are already included and there will be no additional cost charged by the banks in the receiving countries or even hidden fees in the currency exchange. If you want to compare, try by comparing how much money you need to send in Rupiah (including all the fees) so that the receiver can receive the same amount in full. You will be amazed."
                }
              },
              }, {
                "@type": "Question",
                "name": "Can I lock in an exchange rate with Transfez?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The exchange rate is provided when you set up a transfer. Although we will have a feature that will allow you to lock a certain exchange fees for hours or days later for B2B transfer, currently we do not provide a facility to lock in exchange rates in advance. However, once you set up a transfer, the rate will be locked for 3 hours. Transfez reserves the right to cancel any transaction if the money is not credited to Transfez within 3 hours."
                }
              },
              }, {
                "@type": "Question",
                "name": "Why do the rates not change on weekends or at certain other times?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The Forex market is operational during market hours on business days in the particular country. Therefore, the rates may not update when the forex markets are closed on weekends and holidays."
                }
              },
              }, {
                "@type": "Question",
                "name": "",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": ""
                }
              },
              }, {
                "@type": "Question",
                "name": "For how long are the rates locked once I set up a transaction? Is there a time limit for me to make my payment?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Please make the transfer within 3 hours. Your transaction will be terminated if the transfer was made after the time limit, and you would have to redo the transaction process. Your money will be returned in the meantime. Our transactions are time-sensitive due to possibilities of currency change/volatility, which is why we would appreciate that payments are paid within the given time period"
                }
              },
              }, {
                "@type": "Question",
                "name": "My recipient still not receive the money within 1 x 24 hours after status completed. What should I do?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Please contact our Customer Service for any issues you face."
                }
              },
              }, {
                "@type": "Question",
                "name": "My transaction is failed. Why?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Failed transaction can occur due to several reasons, such as: invalid recipient details, transaction limit has been reached, your recipient bank has declined the transaction, barred recipient, unsupported recipient, unexpected errors, and Transfez reserves the right to decline the transaction due to customer that listed in sanction list."
                }
              },
              }, {
                "@type": "Question",
                "name": "Does Transfez offer forward contracts?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "No, we currently do not offer forward contracts."
                }
              },
              }, {
                "@type": "Question",
                "name": "How long is the refund process for my failed transaction?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Submission of refund of failed transactions must be done through the application. Refunds can only be made when your transaction has been declared a failure and your payment has been verified by Transfez. Refund time of failed transactions is 1 x 24 hours on weekdays from the time you submit a refund. If you have not received a refund within the specified time, please contact Customer Service."
                }
              },
              }, {
                "@type": "Question",
                "name": "How can I pay for my transactions?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "In order to use our services, you can make your payment through bank transfer. As soon as you have made and confirm your payment, a notification will be sent to you via app/email."
                }
              },
              }, {
                "@type": "Question",
                "name": "Do you receive payment in cash?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "No we do not accept cash deposits. Currently we only accept payment through Bank Transfer. Please stay tuned and subscribe to our social media platforms to be the first to know when there are new developments!"
                }
              },
              }, {
                "@type": "Question",
                "name": "Does Transfez accept credit card or debit card?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Sorry. Currently we do not accept credit cards and debit card. Please stay tuned and subscribe to our social media platforms to be the first to know when there are new developments!"
                }
              },
              }, {
                "@type": "Question",
                "name": "Can I transfer my money using someone else’s bank account?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "No. In order to keep the process safe and secure, we will not accept payments made from unregistered bank accounts. You can only transfer using your own bank account."
                }
              },
              }, {
                "@type": "Question",
                "name": "Can I send money through somebody I have a joint bank account with?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes. As long as your name reflects as one of the account holders, you can send money through a joint account."
                }
              },
              }, {
                "@type": "Question",
                "name": "What happens if I were to send money using someone else’s account?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Transfez will return the money to you if we detected that the money does not come from your registered account. Help us to keep this environment secure by only using your registered account to make transactions."
                }
              },
              }, {
                "@type": "Question",
                "name": "I have entered wrong recipient details. Can I change it?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You can contact our Customer Service via email or chat for any issues you face before we begin to process your transaction."
                }
              },
              }, {
                "@type": "Question",
                "name": "How do I set up a transfer with Transfez?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Download our app, login to your Transfez account and submit a transfer. The platform will walk you through the process. If you have any queries, please contact our Customer Service."
                }
              },
              }, {
                "@type": "Question",
                "name": "What happens if my payment to recipient is rejected?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We will notify you via email and notification apps about your rejected transfer."
                }
              },
              }, {
                "@type": "Question",
                "name": "I have sent incorrect amount. Can I change it?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You can contact our Customer Service for any issues you face."
                }
              },
              }, {
                "@type": "Question",
                "name": "When will my recipient receive the payment?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Once the money is sent out by Transfez, it depends on the processing time of the receiving bank. You can check the estimated time arrival when make a transaction."
                }
              },
              }, {
                "@type": "Question",
                "name": "Do I need to contact Transfez when I make a payment?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You can confirm your payment in Activity menu by selecting the transaction. After that, we will take care of things for you. However, in case of any queries or problems, you can contact our Customer Service."
                }
              },
              }, {
                "@type": "Question",
                "name": "Once I have transferred my money, will Transfez provide proof that the transaction was successful? How can I keep track of my money transfer?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Once you have completed the process, we will send you a notification, and you can track your money throughout its journey through the app in Activity menu."
                }
              }, {
                "@type": "Question",
                "name": "How do I cancel my transfer with Transfez?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You can contact our Customer Service. Please note, once the funds have been transferred to the recipient, the transaction cannot be cancelled."
                }
              }, {
                "@type": "Question",
                "name": "I have successfully made a transaction with Transfez, but I did not get any notification that my money is sent. What should I do?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Please contact our Customer Service, who will be happy to assist you."
                }
              }, {
                "@type": "Question",
                "name": "I contacted Transfez’s customer service but no one is answering.",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We will provide a response to your inquiries swiftly and no longer than 2 x 24 hours. Please contact us through whatsapp and/or email cs@transfez.com --based on your convenience."
                }
              }, {
                "@type": "Question",
                "name": "What is the current currency availability in Transfez?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The currency we accept is IDR in Indonesia, but will be able to send to over 70 countries and your recipients will accept it with their local currency."
                }
              }, {
                "@type": "Question",
                "name": "From which countries can you send money using Transfez?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Currently you can send money from Indonesia only."
                }
              }, {
                "@type": "Question",
                "name": "To which countries can you send money using Transfez?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You can send money to the following countries using Transfez: Asia Pasific : Bangladesh, China, India, Malaysia, Myanmar, Nepal, Pakistan, Philippines, Singap0re, South Korea, Sri Lanka, Thailand, Vietnam. Europe : Aland Islands, Austria, Azores, Belgium, Canary Islands, Cyprus, Estonia, Finland, France, French Guinea, Germany, Greece, Ireland, Italy, Latvia, Lithuania, Luxembourg, Madeira, Malta, Mayotte, Monaco, Netherlands, Portugal, Saint Barthelme, Saint Martin, Saint Pierre and Miquelon, San Marino, Slovakia, Slovenia, Spain, United Kingdom. Soon we will add: Asia Pasific : Australia, Japan, New Zealand, Cambodia. Africa : Benin, Burkina Faso, Cote d’Ivoire, Ethiopia, Ghana, Guinea-Bissau, Kenya, Mali, Niger, Nigeria, Senegal, Togo. America : USA, Bolivia, Brazil, Chile, Colombia, Cuba, Dominican Republic, Haiti, Mexiko, Nicaragua, Peru, Venezuela. Europe : Croatia, Czech Republic, Denmark, Norway, Poland, Romania, Sweden. Middle East : Saudi Arabia, UAE."
                }
              }, {
                "@type": "Question",
                "name": "What is IFSC Code? What if I do not have my recipient’s IFSC Code?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "An IFSC Code is an alphanumeric code that identifies a particular bank-branch participating in the two main Electronic Fund Settlement Systems in India. You can contact the recipient and get the IFSC code of the bank where they have an account."
                }
              }, {
                "@type": "Question",
                "name": "What are Swift Codes, IBAN Numbers, BSB Codes, and Sort Codes?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "SWIFT Code is a unique identification code for financial and non-financial institutions. IBAN Number is an international system of identifying bank accounts across national borders to facilitate the communication and processing of cross-border transactions. BSB Code is a Bank State Branch (BSB) code used in Australia to identify a bank’s branch. Sort Codes are codes the British and Irish banking industry uses to identify banks when routing money transfers."
                }
              }, {
                "@type": "Question",
                "name": "When will Transfez provide transfer services for business?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Although we are now open for B2B transfer to selected corridors, Transfez is currently working hard to align our business with other countries’ regulations so that we can provide you with a better way to transfer money across many continents for your business transactions. Please stay tuned and subscribe to our social media platforms to be the first to know when there are new developments or please contact us if you need transfer services for business."
                }
              }, {
                "@type": "Question",
                "name": "Can I open a Transfez account for business or company?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, you can register using this form."
                }
              }, {
                "@type": "Question",
                "name": "Can I request for a product demo?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, you can click the request demo button in the Transfez Business page"
                }
              }, {
                "@type": "Question",
                "name": "Can I use a personal account for business purposes?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "No, personal accounts must only be used for personal to personal transaction or personal to business transaction"
                }
              }, {
                "@type": "Question",
                "name": "Can I make batch payment using Transfez Business?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, you can use batch payment for an easier transaction."
                }
              }, {
                "@type": "Question",
                "name": "Does Transfez provide local disbursement?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "For local disbursement, please fill in the form here or contact us to help you with the registration."
                }
              }]
          }`
  const schemaId = `{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Apakah Transfez merupakan penyedia layanan berlisensi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Transfez adalah produk teknologi finansial dari PT. Indo Koala Remittance, institusi finansial Penyelenggara Transfer Dana (PTD) yang berlisensi di Indonesia. Kami diawasi oleh Bank Indonesia dan PPATK di bawah peraturan Anti Pencucian Uang dan Pencegahan Pendanaan Terorisme (APU PPT): 19/10/PBI/2017 dan diatur oleh Bank Indonesia sebagai institusi remitansi resmi dengan nomor izin: 13/77/DASP/30."
      }
    }, {
      "@type": "Question",
      "name": "Apakah aman bagi saya untuk mengirimkan uang melalui Transfez?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Keamanan Anda adalah prioritas kami. Kami memastikan bahwa segala proses terjadi secara transparan untuk memberikan Anda kendali terhadap transaksi - transaksi anda. Proses verifikasi kami menjamin bahwa uang Anda diterima dengan baik oleh orang yang tepat. Transfez diregulasi oleh peraturan Bank Indonesia nomor 19/10/PBI/2017 dengan nomor lisensi no. 13/77/DASP/30 dan regulasi PPATK dengan nomor PER-12/1.02/PPATK/06/13."
      }
    }, {
        "@type": "Question",
        "name": "Bagaimana cara saya membuat akun baru di Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Caranya sangat mudah! Anda hanya memerlukan email atau akun Google/Facebook Anda untuk membuat akun baru melalui aplikasi Transfez."
        }
      }, {
        "@type": "Question",
        "name": "Mengapa sangat penting untuk melengkapi profil saya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Anda perlu melengkapi profil Anda untuk mengirimkan uang melalui layanan kami. Sebagai sebuah institusi finansial, kami wajib melakukan proses verifikasi atas identitas pelanggan kami sesuai regulasi dari negara - negara dimana kami beroperasi."
        }
      }, {
        "@type": "Question",
        "name": "Mengapa saya perlu melengkapi identitas diri saya untuk melakukan transaksi?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Sebagai entitas finansial yang teregulasi, Transfez memiliki komitmen untuk patuh pada Peraturan Anti Pencucian Uang dan Pencegahan Pendanaan Terorisme dari Bank Indonesia dan PPATK. Data Anda akan kami telusuri untuk memastikan bahwa kami tidak melanggar hukum dengan cara apapun."
        }
      }, {
        "@type": "Question",
        "name": "Seberapa pentingkah bagi saya untuk memberikan informasi akurat mengenai identitas saya kepada Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Sebagai sebuah institusi finansial, kami butuh untuk mengetahui siapa yang mengirimkan uang melalui layanan kami dan melakukan verifikasi terhadap identitas pelanggan kami. Karena alasan ini, mohon untuk menggunakan nama asli Anda (bukan nama pendek), alamat yang akurat, nomor telepon dan email aktif yang dapat kami hubungi ketika melengkapi profil Anda di Transfez."
        }
      }, {
        "@type": "Question",
        "name": "Berapa lama waktu yang diperlukan untuk melakukan verifikasi profil sebelum transaksi pertama saya dapat dilakukan?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Verifikasi hanya butuh untuk dilakukan ketika Anda melengkapi profil Anda untuk pertama kalinya. Setelah profil Anda lengkap, kami membutuhkan waktu 1x24 jam di hari kerja sebelum Anda dapat menggunakan layanan kami."
        }
      }, {
        "@type": "Question",
        "name": "Apakah saya dapat mengganti email atau nomor telepon yang telah saya input?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Mohon untuk menghubungi Layanan Pelanggan kami untuk mengganti email dan nomor telepon Anda."
        }
      }, {
        "@type": "Question",
        "name": "Bagaimana cara saya menambahkan penerima pada akun saya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Apabila Anda adalah pengguna yang telah terverifikasi, klik tab ‘Penerima’ atau ‘Buat Penerima Baru’ setelah Anda melakukan proses log in. Apabila Anda baru saja membuat akun, Anda hanya butuh untuk pergi ke ‘Buat Penerima Baru’ ketika mengatur transaksi baru Anda."
        }
      }, {
        "@type": "Question",
        "name": "Mengapa saya diminta untuk melengkapi beberapa dokumen - dokumen lain setelah saya melengkapi profil saya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Sebagai bagian dari kebijakan kami, Transfez meminta Anda untuk memberikan salinan baru dari dokumen Anda. Apabila dokumen yang Anda input sudah melewati batas waktu dan tidak berlaku lagi, kami membutuhkan bantuan Anda untuk mengirimkan salinan terbarunya sebelum kami dapat memproses transaksi Anda."
        }
      }, {
        "@type": "Question",
        "name": "Apakah layanan ini aman untuk digunakan?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Selain berlisensi resmi sebagai Penyelenggara Transfer Dana dari Bank Indonesia, Transfez memiliki pengalaman panjang dalam layanan pengiriman uang. Kami telah sukses dalam membantu pelanggan - pelanggan kami mengirimkan lebih dari puluhan juta dolar. Kami sangat menghormati kepercayaan pelanggan - pelanggan kami dan akan memastikan bahwa uang Anda terkirim dengan aman dalam setiap transaksi."
        }
      }, {
        "@type": "Question",
        "name": "Ketika saya diinstruksikan untuk mengisi biodata saya, apakah informasi saya akan terjaga dengan baik?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Kerahasiaan data dan kepercayaan Anda sangat penting bagi kami. Transfez mengumpulkan data Anda sebagai bagian dari proses pemesanan, untuk kelengkapan profil Anda, serta untuk mematuhi peraturan pihak berwajib. Kami hanya akan mengirimkan email dan notifikasi mengenai berita dan tawaran tertentu dengan izin Anda. Transfez memiliki sistem enskripsi dengan lapisan proteksi yang memastikan keamanan data Anda. Pertukaran informasi melalui server juga dienkripsi menggunakan SSL di server yang berlokasi di Indonesia. Untuk menjaga agar data Anda tetap aman, mohon untuk hanya memberikan informasi mengenai diri Anda pada petugas atau representatif kami. Untuk informasi lebih lanjut, mohon untuk membaca Kebijakan Privasi (Privacy Policy) serta Layanan Pelanggan Transfez (Transfez Service Agreement)."
        }
      }, {
        "@type": "Question",
        "name": "Berapa biaya yang dibutuhkan untuk menggunakan layanan Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Biaya layanan kami berbeda-beda untuk setiap negara tujuan juga koridor transfer. Biaya dapat berubah dan dapat ditinjau setiap saat pada kalkulator mata uang Transfez."
        }
      }, {
        "@type": "Question",
        "name": "Mengapa biaya transaksi Transfez sangat berbeda dari bank dan layanan lain?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Tujuan Transfez adalah untuk menjadikan pengiriman uang internasional terasa seperti pengiriman domestik, secara biaya, kecepatan, dan kenyamanan pelanggan. Tidak seperti bank dan layanan lainnya, kami memiliki likuiditas di setiap negara pengiriman melalui partner bisnis dan juga bank-bank. Kami akan melaksanakan pengiriman uang Anda melalui likuiditas mata uang asing di setiap negara tujuan yang kami layani -- yang membuat biaya kami lebih murah dari bank."
        }
      }, {
        "@type": "Question",
        "name": "Apakah benar bahwa saya dapat menghemat lebih banyak uang apabila menggunakan Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Tentu saja. Di Transfez, kami tidak memiliki biaya - biaya tersembunyi. Yang Anda lihat adalah harga yang Anda bayarkan. Semua biaya sudah termasuk dalam transaksi dan tidak akan ada biaya - biaya tambahan dari bank di negara tujuan Anda maupun biaya tersembunyi dalam pertukaran mata uang asing. Apabila Anda ingin membandingkan harga, masukkan jumlah uang yang akan Anda kirimkan (Rupiah, sudah termasuk biaya layanan) dan lihat bagaimana penerima akan mendapatkan jumlah seutuhnya. Anda akan kagum!"
        }
      }, {
        "@type": "Question",
        "name": "Apakah saya dapat mengunci nilai tukar yang saya inginkan ketika melakukan transaksi dengan Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Nilai tukar akan ditampilkan ketika Anda melakukan pengiriman. Setelah Anda membuat transaksi, kurs Anda akan dikunci selama tiga jam. Transfez berhak untuk membatalkan transaksi apapun jika uang tidak dikreditkan ke Transfez dalam waktu 3 jam. Nantinya kami akan menyediakan fitur yang memungkinkan Anda untuk mengunci nilai tukar yang Anda inginkan selama beberapa jam maupun hari sebelum transaksi (untuk B2B transfer), namun saat ini fitur tersebut masih belum tersedia."
        }
      }, {
        "@type": "Question",
        "name": "Mengapa nilai tukar mata uang asing tidak berganti di akhir pekan maupun di waktu - waktu tertentu?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Pasar valuta asing beroperasi selama jam pasar pada hari kerja di negara - negara tertentu. Oleh karena itu, kurs mungkin tidak diperbarui ketika pasar valuta asing ditutup pada akhir pekan dan hari libur."
        }
      }, {
        "@type": "Question",
        "name": "Untuk berapa lamakah kurs saya dikunci saat melakukan transaksi? Apakah ada batas waktu bagi saya untuk menyelesaikan pembayaran?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Mohon untuk menyelesaikan proses transfer dalam waktu 3 jam. Transaksi Anda akan dibatalkan apabila transfer dilakukan setelah batas waktu yang telah ditentukan dan uang Anda akan dikembalikan dalam kurun waktu tersebut. Apabila ingin melakukan transaksi kembali, Anda harus mengulang proses transaksi dari awal lagi. Transaksi - transaksi kami sensitif terhadap waktu karena adanya perubahan mata uang / volatilitas. Itulah sebabnya kami sangat menghargai bahwa pembayaran dibayarkan dalam periode waktu tertentu."
        }
      }, {
        "@type": "Question",
        "name": "Apa yang harus saya lakukan apabila dana belum masuk ke rekening penerima setelah 1x24 jam status berubah menjadi Completed?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Silahkan hubungi Layanan Pelanggan kami untuk dapat membantu permasalahan Anda."
        }
      }, {
        "@type": "Question",
        "name": "Apa yang menyebabkan transaksi saya gagal?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Transaksi gagal dapat terjadi karena beberapa sebab, di antaranya: data penerima salah, transaksi telah melebihi batas, ditolak bank penerima, penerima masuk dalam daftar sanksi, penerima yang tidak didukung, eror yang tak terduga, dan Transfez memiliki hak untuk menolak transaksi apabila pelanggan adalah orang yang masuk ke dalam Daftar Terduga Teroris dan Organisasi Teroris (DTTOT)."
        }
      }, {
        "@type": "Question",
        "name": "Berapa lama  proses pengembalian dana untuk transaksi saya yang gagal?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Pengajuan pengembalian dana transaksi gagal harus dilakukan melalui aplikasi.Pengembalian dana hanya dapat dilakukan ketika transaksi anda telah dinyatakan gagal dan pembayaran yang anda lakukan telah diverifikasi oleh Transfez. Waktu Proses pengembalian dana transaksi gagal adalah 1 x 24 jam pada hari Kerja terhitung sejak anda melakukan pengajuan pengembalian dana. Jika Anda belum menerima pengembalian dana dalam waktu yang ditentukan, silakan hubungi Customer Service."
        }
      }, {
        "@type": "Question",
        "name": "Apakah Transfez menawarkan kontrak berjangka?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Pengajuan pengembalian dana transaksi gagal harus dilakukan melalui aplikasi. Pengembalian dana hanya dapat dilakukan ketika transaksi Anda telah dinyatakan gagal dan pembayaran yang Anda lakukan telah diverifikasi oleh Transfez. Waktu Proses pengembalian dana transaksi gagal adalah 1 x 24 jam pada hari Kerja terhitung sejak Anda melakukan pengajuan pengembalian dana Jika Anda belum menerima pengembalian dana dalam waktu yang ditentukan, silakan hubungi Customer Service."
        }
      }, {
        "@type": "Question",
        "name": "Apakah Transfez menawarkan kontrak berjangka?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Tidak, saat ini kami tidak menawarkan kontrak berjangka."
        }
      }, {
        "@type": "Question",
        "name": "Bagaimana cara saya melakukan pembayaran untuk transaksi saya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Untuk menggunakan layanan kami, Anda dapat melakukan pembayaran melalui transfer bank. Setelah Anda melakukan dan mengkonfirmasi pembayaran Anda, pemberitahuan melalui aplikasi atau email akan segera dikirimkan kepada Anda."
        }
      }, {
        "@type": "Question",
        "name": "Apakah saya dapat menyimpan uang tunai ke dalam akun Transfez saya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Tidak, kami tidak menerima deposit uang tunai. Saat ini kami hanya menerima pembayaran melalui transfer bank. Tetap ikuti perkembangan kami melalui akun media sosial untuk mengetahui perkembangan - perkembangan baru kami."
        }
      }, {
        "@type": "Question",
        "name": "Apakah Transfez menerima pembayaran melalui kartu kredit atau kartu debit?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Maaf, saat ini kami belum menerima pembayaran melalui kartu kredit dan kartu debit. Tetap ikuti perkembangan kami melalui akun media sosial untuk mengetahui perkembangan - perkembangan baru kami."
        }
      }, {
        "@type": "Question",
        "name": "Apakah saya dapat mengirimkan uang menggunakan rekening bank orang lain?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Tidak. Untuk menjaga keamanan transaksi pelanggan, kami tidak akan menerima pembayaran oleh rekening bank yang tidak dikenal dan tidak diregistrasi di dalam sistem kami. Mohon untuk melakukan registrasi dan mengisi data Anda sebelum melakukan transaksi."
        }
      }, {
        "@type": "Question",
        "name": "Apakah saya dapat mengirimkan uang melalui orang lain yang memiliki rekening bank bersama saya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Ya. Selama nama Anda terdaftar sebagai salah satu pemegang akun, Anda dapat mengirim uang melalui rekening bersama."
        }
      }, {
        "@type": "Question",
        "name": "Apa yang akan terjadi apabila saya mengirim uang menggunakan akun orang lain?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Transfez akan mengembalikan uang Anda apabila kami mendeteksi bahwa uang yang kami terima tidak dikirim dari akun yang teregistrasi. Bantu kami untuk menjaga keamanan layanan ini dengan hanya menggunakan akun yang telah diregistrasi untuk melakukan transaksi."
        }
      }, {
        "@type": "Question",
        "name": "Saya telah mencantumkan informasi penerima yang salah. Apakah saya dapat mengubahnya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Anda dapat menghubungi Layanan Pelanggan kami melalui email atau chat untuk isu - isu yang Anda hadapi sebelum kami memproses transaksi anda."
        }
      }, {
        "@type": "Question",
        "name": "Bagaimana cara saya memproses pengiriman uang dengan Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Masuk ke dalam akun Transfez Anda dan mulai mengirimkan uang. Layanan kami akan membawa Anda melalui seluruh proses transaksi. Apabila Anda memiliki pertanyaan, mohon hubungi Layanan Pelanggan kami."
        }
      }, {
        "@type": "Question",
        "name": "Apa yang terjadi apabila pembayaran saya ke penerima ditolak?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Kami akan mengabari Anda via email dan notifikasi mengenai pengiriman uang yang ditolak."
        }
      }, {
        "@type": "Question",
        "name": "Saya telah mengirimkan uang dengan jumlah yang salah. Apakah saya dapat menggantinya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Anda dapat menghubungi Layanan Pelanggan kami untuk mengatasi masalah yang Anda hadapi. Klik di sini."
        }
      }, {
        "@type": "Question",
        "name": "Kapan penerima akan mendapatkan kiriman uangnya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Setelah uang dikirim oleh Transfez, lamanya uang akan diterima bergantung pada proses dari bank penerima. Anda dapat melihat estimasi waktu pengiriman saat Anda melakukan transaksi."
        }
      }, {
        "@type": "Question",
        "name": "Apakah saya butuh untuk menghubungi Transfez saat saya melakukan pembayaran?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Anda dapat mengkonfirmasi pembayaran Anda di menu Activity dengan memilih transaksi yang ingin Anda lihat. Setelah itu, kami akan mengurus semuanya untuk Anda. Namun, apabila Anda mengalami suatu kendala, mohon untuk menghubungi Layanan Pelanggan kami. Klik disini."
        }
      }, {
        "@type": "Question",
        "name": "Begitu saya selesai melakukan pembayaran, apakah Transfez akan memberikan bukti bahwa transaksi saya telah sukses dilakukan? Bagaimana cara saya melacak pengiriman uang saya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Ya. Begitu pembayaran Anda telah selesai dilakukan, kami akan mengirimkan notifikasi pada Anda dan Anda dapat melacak pergerakan uang Anda melalui menu Activity di aplikasi kami."
        }
      }, {
        "@type": "Question",
        "name": "Bagaimana cara saya membatalkan transaksi saya dengan Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Anda dapat menghubungi Layanan Pelanggan kami. Harap dicatat, setelah dana berhasil dikirim ke penerima, transaksi tidak dapat dibatalkan."
        }
      }, {
        "@type": "Question",
        "name": "Saya telah menyelesaikan transaksi saya dengan Transfez, namun saya tidak mendapat notifikasi bahwa uang saya telah dikirimkan. Apa yang harus saya lakukan?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Mohon untuk mengontak Layanan Pelanggan kami, yang akan dengan senang hati membantu Anda dalam memastikan bahwa transaksi Anda telah dikirim."
        }
      }, {
        "@type": "Question",
        "name": "Saya telah menghubungi Layanan Pelanggan Transfez namun tidak mendapat jawaban.",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Layanan Pelanggan kami akan memberikan respon pada pertanyaan - pertanyaan Anda dalam periode waktu tidak lebih dari 2x24 jam. Mohon untuk menghubungi kami melalui whatsapp dan/atau email ke cs@transfez.com."
        }
      }, {
        "@type": "Question",
        "name": "Apa saja mata uang yang tersedia dalam layanan Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Mata uang yang kami terima adalah Rupiah, namun kami dapat mengirimkan dana Anda ke lebih dari 70 negara yang akan diterima dalam mata uang negara tersebut."
        }
      }, {
        "@type": "Question",
        "name": "Dari negara manakah saya dapat mengirimkan uang melalui Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Saat ini, Anda hanya dapat mengirimkan uang dari Indonesia."
        }
      }, {
        "@type": "Question",
        "name": "Ke negara manakah saya dapat mengirimkan uang melalui Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Anda dapat mengirimkan uang ke negara - negara di bawah ini: Asia Pasifik: Bangladesh, Cina, India, Malaysia, Myanmar, Nepal, Pakistan, Filipina, Singapura, Korea Selatan, Sri Lanka, Thailand, Vietnam. Eropa: Kepulauan Aland, Austria, Azores, Belgia, Pulau Canary, Cyprus, Estonia, Finland, Perancis, Guyana Perancis, Jerman, Yunani, Irlandia, Italia, Latvia, Lithuania, Luxembourg, Madeira, Malta, Mayotte, Monako, Belanda, Portugal, Saint Barthelme, Saint Martin, Saint Pierre dan Miquelon, San Marino, Slovakia, Slovenia, Spanyol, Inggris. Dalam waktu dekat, kami akan menambahkan pengiriman ke negara-negara di bawah ini: Asia Pasifik : Australia, Jepang, Selandia Baru, Kamboja. Afrika : Benin, Burkina Faso, Cote d’Ivoire, Ethiopia, Ghana, Guinea-Bissau, Kenya, Mali, Niger, Nigeria, Senegal, Togo. Amerika : USA, Bolivia, Brazil, Chile, Colombia, Cuba, Dominican Republic, Haiti, Meksiko, Nicaragua, Peru, Venezuela. Eropa : Kroasia, Czech Republic, Denmark, Norwegia, Polandia, Roma, Swedia. Timur Tengah : Arab Saudi, UAE."
        }
      }, {
        "@type": "Question",
        "name": "Apa itu Kode IFSC? Bagaimana jika saya tidak memiliki Kode IFSC penerima saya?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Kode IFSC adalah kode alfanumerik yang mengidentifikasi cabang bank tertentu yang berpartisipasi dalam dua Sistem Penyelesaian Dana Elektronik utama di India. Anda dapat menghubungi penerima dan mendapatkan kode IFSC bank akun mereka."
        }
      }, {
        "@type": "Question",
        "name": "Apa yang dimaksud dengan Swift Codes, IBAN Numbers, BSB Codes, dan Sort Code?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Kode SWIFT adalah kode identifikasi unik untuk lembaga keuangan dan non-keuangan. IBAN Number adalah sistem internasional untuk mengidentifikasi rekening bank lintas batas negara untuk memfasilitasi komunikasi dan pemrosesan transaksi lintas batas. BSB Code adalah kode Bank State Branch (BSB) yang digunakan di Australia untuk mengidentifikasi cabang bank. Sort Codes adalah kode yang digunakan industri perbankan Inggris dan Irlandia untuk mengidentifikasi bank saat mengarahkan transfer uang.
          "
        }
      }, {
        "@type": "Question",
        "name": "Kapan Transfez akan menyediakan layanan transfer untuk bisnis?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Meskipun saat ini kami terbuka untuk melakukan layanan transfer B2B ke koridor tertentu, Transfez bekerja keras untuk menyelaraskan bisnis kami dengan peraturan negara lain sehingga kami dapat memberi Anda cara yang lebih baik untuk mentransfer uang ke berbagai benua untuk transaksi bisnis Anda. Tetap ikuti perkembangan kami melalui akun media sosial untuk mengetahui perkembangan - perkembangan baru kami atau hubungi kami jika Anda memerlukan layanan transfer dana untuk bisnis."
        }
      }, {
        "@type": "Question",
        "name": "Apakah saya dapat membuat akun Transfez untuk bisnis?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Ya, Anda bisa mendaftar dengan mengisi formulir di sini."
        }
      }, {
        "@type": "Question",
        "name": "Apakah saya bisa meminta demo produk Transfez Bisnis terlebih dahulu?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Ya, Anda bisa klik tombol request demo pada laman Transfez Bisnis"
        }
      }, {
        "@type": "Question",
        "name": "Apakah saya dapat menggunakan akun personal untuk melakukan bisnis?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Tidak, akun personal Anda hanya dapat digunakan untuk kebutuhan individu ke individu atau individu ke bisnis (Personal to Business transaction)"
        }
      }, {
        "@type": "Question",
        "name": "Apakah saya dapat melakukan batch payment/pembayaran massal (menggabungkan beberapa tagihan menjadi satu transaksi pembayaran) dengan Transfez Bisnis?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Ya, Anda bisa mengunggah beberapa tagihan dalam satu kali transaksi."
        }
      }, {
        "@type": "Question",
        "name": "Apakah ada layanan pembayaran lokal di Transfez?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Untuk pembayaran lokal, Anda dapat mengisi formulir di sini atau hubungi kami untuk bantuan pendaftaran."
        }
      },
    ]
  }`
  const schema = isEnglish ? schemaEn : schemaId
  return schema
}
