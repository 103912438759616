export const CannonicalGenerator = ({
  location,
  swiftCanonical,
  isHomePage,
  isEnglish,
}) => {
  const { pathname } = location || {}

  const canonicalSwift = isEnglish
    ? `https://www.transfez.com/en/swift-codes/${swiftCanonical}`
    : `https://www.transfez.com/swift-codes/${swiftCanonical}`

  const canonicalHome = isEnglish
    ? `https://www.transfez.com/en`
    : `https://www.transfez.com`

  const canonicalLink = `https://www.transfez.com${pathname}`

  const CanonicalFunct = () => {
    if (swiftCanonical) return canonicalSwift
    if (isHomePage) return canonicalHome
    return canonicalLink
  }

  const canonical = CanonicalFunct()

  return canonical
}
