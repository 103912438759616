export const SchemaSendMoney = (isEnglish, country) => {
  const schemaEn = `{
        "@context": "https://schema.org",
        "@type": "HowTo",
        "name": "How to send money online to ${country.country_en}",
        "description": "Welcome to the new best way to send money to ${country.country_en} easily, quick, safe & cheap.",
        "supply": [
          {
            "@type": "HowToSupply",
            "name": "Transfez App"
          }
        ],
        "tool": [
          {
            "@type": "HowToTool",
            "name": "Transfez App"
          }
        ],
        "step": [
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/en",
            "name": "Register a free account",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Download the Transfez app and register a free account. No risk, no commitment."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/en",
            "name": "Determine the transfer amount",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Use our smart calculator to instantly know the fees & exchange rate. We’ll also give you an estimate on how fast your money will arrive to your recipient."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/en",
            "name": "Identity verification",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "To safeguard your money at all times, we’ll do an identity verification process according to the established rules & regulations."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/en",
            "name": "Enter your recipient's information",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Next, enter your recipient’s bank account information."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/en",
            "name": "Execute payment",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "You can pay directly to our local bank account via ATM or internet banking."
            }]
          },
          {
            "@type": "HowToStep",
            "url": "https://www.transfez.com/en",
            "name": "Time to relax",
            "itemListElement": [{
              "@type": "HowToDirection",
              "text": "Congratulations, your money is on its way. Now it’s time to grab some fresh juice, relax, and wait for the “Thank you” message from your recipient."
            }]
          }
        ]
      }`
  const schemaId = `{
    "@context": "https://schema.org",
    "@type": "HowTo",
    "name": "Cara kirim uang secara online ke ${country.country_id}",
    "description": "Nikmati layanan transfer / kirim uang ke ${country.country_id} menggunakan Aplikasi Transfez dengan mudah, cepat, hemat, aman, tepercaya dan transparan.",
    "supply": [
      {
        "@type": "HowToSupply",
        "name": "Transfez App"
      }
    ],
    "tool": [
      {
        "@type": "HowToTool",
        "name": "Transfez App"
      }
    ],
    "step": [
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/",
        "name": "Daftar akun gratis",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Download aplikasi Transfez dan daftar akun secara gratis. Tanpa resiko tanpa komitmen."
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/",
        "name": "Tentukan jumlah yang akan dikirim",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Gunakan kalkulator cerdas kami untuk langsung mengetahui perhitungan biaya transfer dan estimasi kapan uang Anda akan sampai."
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/",
        "name": "Verifikasi identitas",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Untuk menjaga keamanan uang Anda, kami akan melakukan verifikasi identitas sesuai dengan peraturan yang berlaku."
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/",
        "name": "Masukkan informasi penerima uang",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Selanjutnya masukkan informasi detail akun bank penerima uang Anda."
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/",
        "name": "Lakukan pembayaran",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Lakukan pembayaran ke rekening lokal Transfez melalui ATM atau internet banking."
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/",
        "name": "Saatnya bersantai",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Selamat, uang Anda telah terkirim. Saatnya mengambil jus segar dan menunggu pesan Terima kasih dari penerima uang Anda."
        }]
      }
    ]
  }`
  const schema = isEnglish ? schemaEn : schemaId
  return schema
}

export const SchemaSendMoneyBreadcrumb = (isEnglish, country) => {
  const { slug } = country || {}
  const schemaEn = `{
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.transfez.com/en/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Countries",
        "item": "https://www.transfez.com/en/countries/"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Country Details",
        "item": "https://www.transfez.com/en/countries/send-money-to-${slug}/"
      }]
    }`

  const schemaId = `{
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.transfez.com/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Countries",
        "item": "https://www.transfez.com/countries/"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Country Details",
        "item": "https://www.transfez.com/countries/send-money-to-${slug}/"
      }]
    }`

  const schema = isEnglish ? schemaEn : schemaId
  return schema
}
