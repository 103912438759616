export const SchemaSgReferral = isEnglish => {
  const schemaEn = `{
            "@context": "https://schema.org",
            "@type": "HowTo",
            "name": "How To use the referral code",
            "description": "Share your referral code and get 7 SGD for every user who use your code for their first transaction of minimum 350 SGD from Singapore to Indonesia.",
            "step": [
              {
                "@type": "HowToStep",
                "url": "https://www.transfez.com/en/sg/referral",
                "name": "Enter the referral code",
                "itemListElement": [{
                  "@type": "HowToDirection",
                  "text": "You can enter the code during the registration process or before making your first transaction"
                }]
              },
              {
                "@type": "HowToStep",
                "url": "https://www.transfez.com/en/sg/referral",
                "name": "Enter the code that started with TSG",
                "itemListElement": [{
                  "@type": "HowToDirection",
                  "text": "Make sure you use Transfez Singapore Region referral code that started with TSG"
                }]
              },
              {
                "@type": "HowToStep",
                "url": "https://www.transfez.com/en/sg/referral",
                "name": "Get a 3x FREE transaction fee voucher",
                "itemListElement": [{
                  "@type": "HowToDirection",
                  "text": "Enjoy 3x free transaction fee after you have used your referral code and completed your first transaction"
                }]
              }
            ]
          }`
  const schemaId = `{
    "@context": "https://schema.org",
    "@type": "HowTo",
    "name": "Bagaimana Cara Menggunakan Kode Referral",
    "description": "Bagikan kode referral kamu dan dapatkan 7 SGD untuk setiap teman yang menggunakan kode kamu saat transaksi pertama senilai minimal 350 SGD dari Singapura ke Indonesia",
    "step": [
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/sg/referral",
        "name": "Masukkan kode referral yang kamu dapat",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Kamu bisa memasukkan kode saat proses registrasi atau sebelum melakukan transaksi pertama"
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/sg/referral",
        "name": "Masukkan kode referral yang diawali dengan TSG",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Pastikan menggunakan kode referral Transfez Regional Singapura yang berawalan TSG ya"
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/sg/referral",
        "name": "Dapatkan voucher GRATIS 3x biaya transaksi",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Nikmati 3x transaksi gratis setelah berhasil menggunakan kode referral dan menyelesaikan transaksi pertama"
        }]
      }
    ]
  }
  `
  const schema = isEnglish ? schemaEn : schemaId
  return schema
}

export const SchemaSgReferralInvite = isEnglish => {
  const schemaEn = `{
    "@context": "https://schema.org",
    "@type": "HowTo",
    "name": "How to invite your friend in referral",
    "description": "Share your referral code and get 7 SGD for every user who use your code for their first     transaction of minimum 350 SGD from Singapore to Indonesia.",
    "step": [
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/en/sg/referral",
        "name": "Go to referral page in the Transfez App",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Click on the share button to start inviting your friends"
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/en/sg/referral",
        "name": "Share your referral code with friends",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "You can invite as many friends as you want to earn more"
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/en/sg/referral",
        "name": "Earn 7 SGD for each user who use your code",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Please make sure your friends have been verified and completed their first transaction of    minimum 350 SGD"
        }]
      }
    ]
  }`
  const schemaId = `{
    "@context": "https://schema.org",
    "@type": "HowTo",
    "name": "Bagaimana Cara Mengajak Teman Menggunakan Kode Referral",
    "description": "Bagikan kode referral kamu dan dapatkan 7 SGD untuk setiap teman yang menggunakan kode kamu       saat transaksi pertama senilai minimal 350 SGD dari Singapura ke Indonesia",
    "step": [
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/sg/referral",
        "name": "Masuk ke halaman referral di aplikasi Transfez",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Klik tombol bagikan dan mulai mengajak teman"
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/sg/referral",
        "name": "Bagikan kode referral kamu ke teman",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Kamu bisa mengajak sebanyak-banyaknya untuk mendapatkan komisi lebih banyak"
        }]
      },
      {
        "@type": "HowToStep",
        "url": "https://www.transfez.com/sg/referral",
        "name": "Dapatkan 7 SGD untuk setiap teman yang pakai kode kamu",
        "itemListElement": [{
          "@type": "HowToDirection",
          "text": "Pastikan teman kamu sudah terverifikasi dan berhasil melakukan transaksi pertama senilai minimal       350 SGD ya"
        }]
      }
    ]
  }`
  const schema = isEnglish ? schemaEn : schemaId
  return schema
}
