import React from "react"
import { Helmet } from "react-helmet"
import { SchemaAffiliate } from "../Schema/SchemaAffiliate"
import {
  SchemaCountries,
  SchemaHowToCountries,
} from "../Schema/SchemaCountries"
import { SchemaFaq } from "../Schema/SchemaFaq"
import { SchemaHomepage } from "../Schema/SchemaHomepage"
import { SchemaSendMoney, SchemaSendMoneyBreadcrumb } from "../Schema/SchemaSendMoney"
import {
  SchemaSgReferral,
  SchemaSgReferralInvite,
} from "../Schema/SchemaSgReferral"
import { MetaDataProps } from "./MetaData"

export const Schema = ({ title, isEnglish, country }) => {
  const {
    isAffiliate,
    isSgReferral,
    isHomepage,
    isSendMoney,
    isFaq,
    isCountries,
  } = MetaDataProps(title)

  return (
    <Helmet>
      {isHomepage && (
        <script type="application/ld+json">{SchemaHomepage(isEnglish)}</script>
      )}
      {isSendMoney && (
        <script type="application/ld+json">
          {SchemaSendMoney(isEnglish, country)}
        </script>
      )}
      {isSendMoney && (
        <script type="application/ld+json">
          {SchemaSendMoneyBreadcrumb(isEnglish, country)}
        </script>
      )}
      {isAffiliate && (
        <script type="application/ld+json">{SchemaAffiliate(isEnglish)}</script>
      )}
      {/* {isSgReferral && (
        <script type="application/ld+json">
          {SchemaSgReferralInvite(isEnglish)}
        </script>
      )} */}
      {isSgReferral && (
        <script type="application/ld+json">
          {SchemaSgReferral(isEnglish)}
        </script>
      )}
      {isFaq && (
        <script type="application/ld+json">{SchemaFaq(isEnglish)}</script>
      )}
      {isCountries && (
        <script type="application/ld+json">
          {SchemaHowToCountries(isEnglish)}
        </script>
      )}
      {isCountries && (
        <script type="application/ld+json">{SchemaCountries(isEnglish)}</script>
      )}
    </Helmet>
  )
}
