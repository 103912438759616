export const SchemaAffiliate = isEnglish => {
  const schemaEn = `{
        "@context": "https://www.transfez.com/en/affiliate/",
        "@type": "Affiliate Page",
        "mainEntity": [{
          "@type": "Question",
          "name": "What is Transfez Affiliate Program?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Transfez Affiliate Program allows you to earn commission for referrals using your unique affiliate link in articles, emails, blog posts, social media or e-commerce sites. Joining the program gives you a simple way to add value to your site and audience while creating new revenue opportunities. The affiliate program is different from the Transfez Invite program (Referral Code), which is only for referring friends and family from your personal network."
          }
        }, {
          "@type": "Question",
          "name": "Who is eligible to become Transfez Affiliate?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Anyone who has social media, blog or access to socialize Transfez"
          }
        }, {
          "@type": "Question",
          "name": "Is there any membership fee?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "There is no sign up fee, it’s free"
          }
        }, {
          "@type": "Question",
          "name": "How long will the affiliate link expired?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Expired when the contract ends."
          }
        }, {
          "@type": "Question",
          "name": "How can I track my users transactions?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"We will provide you with a tracking software dashboard for you to track transaction use your code and commission."}
          },
          {
          "@type": "Question",
          "name": "What do I get for promoting Transfez?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Every time a user of your affiliate code makes a transaction and the transaction is successful, you will earn a commission for each transaction."}
          },
          {
          "@type": "Question",
          "name": "Until when I can received the reward for the success transactions?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"You will receive the reward continuously on every successful transactions, during the contract period as Transfez affiliate partner is still valid."}
          },
          {
          "@type": "Question",
          "name": "Is there any limit on commission that I can receive?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"There is no limit on commission. You are encouraged to bring in as many users as you can."}
          },
          {
          "@type": "Question",
          "name": "How will I being paid?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"You can receive in IDR Bank account or foreign bank account. For foreign bank account there will be additional fee charged to you."}
          },
          {
          "@type": "Question",
          "name": "How many times can I withdraw per month?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"One time per month."}
          },
          {
          "@type": "Question",
          "name": "Any minimum credit to withdraw?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Yes. It will be in IDR 200,000 equivalent."}
          },
          {
          "@type": "Question",
          "name": "How do I promote Transfez?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"You can promote Transfez in anywhere, in your website, blog, Instagram, Facebook, Twitter or other social media, sending marketing email, etc."}
          },
          {
          "@type": "Question",
          "name": "What I should not do?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"You are not allowed to promote our service on any hate, adult, or other related sites that host content in violation of law."}
          }]
        }`
  const schemaId = `{
        "@context": "https://www.transfez.com/affiliate/",
        "@type": "Affiliate Page",
        "mainEntity": [{
          "@type": "Question",
          "name": "Apa itu Program Afiliasi Transfez?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Program Afiliasi Transfez memungkinkan Anda untuk mendapatkan komisi jika ada menaruh kode referensi unik Anda dalam artikel, email, posting blog, media sosial atau situs website. Bergabung dengan program ini memberi Anda cara sederhana untuk menambah nilai ke situs dan audiens Anda sambil menciptakan peluang pendapatan baru. Program afiliasi berbeda dari Transfez Invite Program (Referral Code), yang hanya untuk merujuk teman dan keluarga dari jaringan pribadi Anda."
          }
        }, {
          "@type": "Question",
          "name": "Siapa yang berhak menjadi Afiliasi Transfez?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Siapa pun yang memiliki media sosial, blog atau akses untuk mensosialisasikan Transfez"
          }
        }, {
          "@type": "Question",
          "name": "Apakah ada biaya untuk bergabung?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Tidak ada biaya pendaftaran, Anda dapat bergabung dengan gratis."
          }
        }, {
          "@type": "Question",
          "name": "Berapa lama tautan afiliasi akan kadaluarsa?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Ketika masa kerjasama nya berakhir."
          }
        }, {
          "@type": "Question",
          "name": "Bagaimana saya bisa melacak transaksi pengguna saya?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Kami akan memberi Anda akses platform untuk dapat melacak transaksi yang menggunakan kode Anda dan komisi."}
          },
          {
          "@type": "Question",
          "name": "Apa yang saya dapatkan untuk mempromosikan Transfez?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Setiap kali pengguna kode afiliasi Anda melakukan transaksi dan transaksi nya sudah sukses, Anda akan mendapatkan komisi untuk setiap transaksinya"}
          },
          {
          "@type": "Question",
          "name": "Sampai kapan saya bisa menerima hadiah untuk transaksi sukses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Anda akan menerima hadiah terus menerus atas setiap transaksi yang berhasil, selama masa kontrak menjadi mitra afiliasi Transfez masih berlaku."}
          },
          {
          "@type": "Question",
          "name": "Apakah ada batasan komisi yang dapat saya terima?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Tidak ada batasan komisi. Anda dipersilahkan membawa sebanyak mungkin pengguna."}
          },
          {
          "@type": "Question",
          "name": "Bagaimana saya akan dibayar?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Anda dapat menerima dalam rekening Bank IDR atau rekening bank asing. Untuk rekening bank asing akan ada biaya tambahan yang dibebankan kepada Anda."}
          },
          {
          "@type": "Question",
          "name": "Berapa kali saya bisa menarik per bulan?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Satu kali per bulan."}
          },
          {
          "@type": "Question",
          "name": "Adakah kredit minimum untuk ditarik?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Iya. Itu akan menjadi setara dengan Rp 200.000."}
          },
          {
          "@type": "Question",
          "name": "Bagaimana cara saya mempromosikan Transfez?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Anda dapat mempromosikan Transfez di mana saja, di situs web, blog, Instagram, Facebook, Twitter atau media sosial lainnya, mengirim email pemasaran, dll."}
          },
          {
          "@type": "Question",
          "name": "Apa yang seharusnya tidak saya lakukan?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"Anda tidak diperbolehkan mempromosikan layanan kami pada konten atau situs Kebencian, situs orang dewasa, atau situs terkait lainnya yang berisikan konten yang melanggar hukum."}
          }]
        }`
  const schema = isEnglish ? schemaEn : schemaId
  return schema
}
